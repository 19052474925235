import { Link, LinkProps, Stack } from '@whiteaway/ui';

interface Props extends LinkProps {
  /**
   *  The prop specifies the target link
   */
  href: string;
}

/**
 * The HeaderTopBarLink component is used to render the contact links in the topbar header.
 */
export const HeaderTopBarLink: React.FC<Props> = (props) => {
  const { children, href, ...rest } = props;

  return (
    <Stack
      spacing={1}
      direction="row"
      align="center"
      asElement={Link}
      color="text.primaryLight"
      variant="caption"
      href={href}
      {...rest}
    >
      {children}
    </Stack>
  );
};
