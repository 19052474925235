import styled, { css } from 'styled-components';

import { Container } from '@whiteaway/ui';

interface Props extends React.HTMLAttributes<HTMLDivElement> {}

/**
 * The HeaderContent component is used to render out the main content inside the header.
 * This content can be dynamic between the public and the private versions of the header.
 */
export const HeaderContent: React.FC<Props> = (props) => {
  const { children, ...rest } = props;

  return (
    <HeaderContentRoot {...rest}>
      <Container>{children}</Container>
    </HeaderContentRoot>
  );
};

export default HeaderContent;

const HeaderContentRoot = styled.div`
  ${({ theme }) => css`
    width: 100%;
    display: flex;
    align-items: center;

    padding: ${({ theme }) => theme.getSpacing(3, 0)};
    background-color: ${theme.colors.background.subtle};
    border-bottom: 1px solid ${theme.colors.outline.default};
  `}
`;
