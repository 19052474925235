'use client';

import styled from 'styled-components';
import Link, { LinkProps } from 'next/link';

import { Button as ArcadeButton, ButtonProps } from '@whiteaway/ui';

interface Props extends ButtonProps, Pick<LinkProps, 'href'> {}

/**
 * The component contains a help link inside of the footer.
 */
export const FooterHelpLink: React.FC<Props> = (props) => {
  const { children, size = 'sm', variant = 'outlined', color = 'neutral', ...rest } = props;

  return (
    <Root size={size} variant={variant} color={color} asElement={Link} {...rest}>
      {children}
    </Root>
  );
};

const Root = styled(ArcadeButton)`
  ${({ theme }) => ({ ...theme.typography.body })};
`;
