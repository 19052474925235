import { useState, useEffect } from 'react';
import styled, { css, useTheme } from 'styled-components';

import { Slide, useViewport } from '@whiteaway/ui';

import { useScrollDirection, useScrollPosition } from '@/hooks';

const DESKTOP_HEADER_SCROLL_THRESHOLD = 150;
const MOBILE_HEADER_SCROLL_THRESHOLD = 50;

interface Props extends React.HTMLAttributes<HTMLHeadElement> {}

/**
 * The component is the basic building block for both the public and the private header.
 */
export const Header: React.FC<Props> = (props) => {
  const { children, ...rest } = props;

  const [showHeader, setShowHeader] = useState(true);

  const { transitions } = useTheme();

  const { isDesktop } = useViewport();

  const scrollPosition = useScrollPosition();

  const { scrollDirection } = useScrollDirection();

  const scrollThreshold = isDesktop ? DESKTOP_HEADER_SCROLL_THRESHOLD : MOBILE_HEADER_SCROLL_THRESHOLD;

  /**
   * The effect is responsible for showing and hiding the header based on the scroll direction and the scroll position.
   */
  useEffect(() => {
    if (scrollDirection === 'up') {
      setShowHeader(true);
    } else if (scrollDirection === 'down' && scrollPosition.top > scrollThreshold) {
      setShowHeader(false);
    }
  }, [isDesktop, scrollDirection, scrollPosition, scrollThreshold]);

  return (
    <Slide in={showHeader} timeout={transitions.duration.slowest} appear={false}>
      <Root {...rest}>{children}</Root>
    </Slide>
  );
};

export default Header;

const Root = styled.header`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    flex-direction: column;

    position: sticky;
    top: 0;
    z-index: ${theme.zIndexes.sticky};
  `}
`;
