'use client';

import styled from 'styled-components';

import { Stack, StackProps } from '@whiteaway/ui';

interface Props extends StackProps {}

/**
 * The component holds the wrapper around the help links in the footer.
 */
export const FooterHelpLinks: React.FC<Props> = (props) => {
  const { children } = props;

  return (
    <Root direction="row" spacing={3}>
      {children}
    </Root>
  );
};

const Root = styled(Stack)`
  flex-wrap: wrap;
`;
