'use client';

import styled, { css } from 'styled-components';

import { Stack, StackProps } from '@whiteaway/ui';

interface Props extends StackProps {}

/**
 * The component holds the root for the info section of the footer.
 */
export const FooterInfoRoot: React.FC<Props> = (props) => {
  const { children, ...rest } = props;

  return (
    <Root direction="row" align="flex-end" spacing={16} {...rest}>
      {children}
    </Root>
  );
};

const Root = styled(Stack)`
  ${({ theme }) => css`
    ${theme.breakpoints.below('desktop')} {
      flex-direction: column;
      align-items: flex-start;
      gap: ${theme.getSpacing(4)};
    }
  `}
`;
