import styled, { css } from 'styled-components';

import { Stack as ArcadeStack, GridItem } from '@whiteaway/ui';

export const Root = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    justify-content: space-between;

    width: 100%;
    height: 32px;

    color: ${theme.colors.text.inverse};
    background-color: ${theme.colors.surface.inverse};

    ${(props) => props.theme.breakpoints.below('tablet')} {
      text-align: center;
    }
  `}
`;

export const Stack = styled(ArcadeStack)`
  ${({ theme }) => css`
    ${theme.breakpoints.below('desktop')} {
      justify-content: center;
    }
  `}
`;

export const DesktopGridItem = styled(GridItem)`
  ${(props) => props.theme.breakpoints.below('desktop')} {
    display: none;
  }
`;
