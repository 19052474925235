'use client';

import { PropsWithChildren, createContext, useContext } from 'react';

import { Profile } from '@/bff-client';

export interface ProfileContextProps extends PropsWithChildren {
  profile: Profile;
}

export interface ProfileContextValues {
  profile: Profile;
}

export const ProfileContext = createContext({} as ProfileContextValues);

export const ProfileProvider = (props: ProfileContextProps): React.ReactElement => {
  const { children, profile } = props;

  return <ProfileContext.Provider value={{ profile }}>{children}</ProfileContext.Provider>;
};

export const useProfile = (): ProfileContextValues => {
  return useContext(ProfileContext);
};
