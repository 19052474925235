'use client';
import { PropsWithChildren, createContext, useContext, useState } from 'react';

export interface ErrorBannerContextProps extends PropsWithChildren {}

export interface ErrorBannerContextValues {
  errorBanner: boolean;
  setErrorBanner: React.Dispatch<boolean>;
}

export const ErrorBannerContext = createContext({} as ErrorBannerContextValues);

export const ErrorBannerProvider = (props: ErrorBannerContextProps): React.ReactElement => {
  const { children } = props;

  const [errorBanner, setErrorBanner] = useState(false);

  return <ErrorBannerContext.Provider value={{ errorBanner, setErrorBanner }}>{children}</ErrorBannerContext.Provider>;
};

export const useErrorBanner = (): ErrorBannerContextValues => {
  return useContext(ErrorBannerContext);
};
