import Image from 'next/image';
import Link, { LinkProps } from 'next/link';

import { translations } from '@/contexts';
import { ROUTES } from '@/config';

interface Props extends Omit<LinkProps, 'href'> {}

/**
 * The HeaderLogo component is used to render out the logo inside the header.
 */
export const HeaderLogo: React.FC<Props> = (props) => {
  const { logoAltText } = translations.shared;

  return (
    <Link href={ROUTES.PROTECTED.HOME} {...props}>
      <Image src="/logo.png" alt={logoAltText} width={294} height={68} />
    </Link>
  );
};

HeaderLogo.displayName = 'HeaderLogo';

export default HeaderLogo;
