/**
 * The function is used to get a single cookie based on its name.
 * @param name The name for the cookie
 * @param initialValue The initial value for the cookie.
 * @returns The cookie or null, if no cookie is found with the given name.
 */
export const getCookie = (name: string, initialValue?: string): string | null => {
  if (typeof window === 'undefined') return null;

  const value = document.cookie.split('; ').reduce((r, v) => {
    const parts = v.split('=');
    return parts[0] === name ? decodeURIComponent(parts[1]) : r;
  }, '');

  return value ? value : initialValue || null;
};

export default getCookie;
