import React from 'react';
import styled from 'styled-components';

interface Props extends React.PropsWithChildren {}

/**
 * The HeaderLinkLabel component is used to render out the label of a header link and hide the label when below desktop.
 */
export const HeaderLinkLabel: React.FC<Props> = (props) => {
  const { children } = props;

  return <Root>{children}</Root>;
};

export default HeaderLinkLabel;

const Root = styled.span`
  ${(props) => props.theme.breakpoints.below('desktop')} {
    display: none;
  }
`;
