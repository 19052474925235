import { CookieConsent } from '@/contexts';

const PREFIX = 'cookie_cat_';

/**
 * Parse the cookie string and extract the consent_approved array
 * @param cookie cookies string
 * @returns {CookieConsent[]} - An array of consents with the 'cookie_cat_' prefix removed.
 */
export const parseCookieConsents = (cookie: string) => {
  // Parse the cookie value and extract the consents_approved array
  const cookieConsent = JSON.parse(cookie).consents_approved;

  //Map through each consent in the cookieConsent array and remove the 'cookie_cat_' prefix
  return cookieConsent.map((consent: string) => consent.replace(PREFIX, '')) as CookieConsent[];
};
