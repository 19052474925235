'use client';

import { PropsWithChildren, createContext, useCallback, useEffect, useState } from 'react';

import { getCookieConsents } from './utils';

export type CookieConsent = 'marketing' | 'necessary' | 'functional' | 'statistic' | 'unclassified';

export interface CookieConsentContextProps extends PropsWithChildren {}

export const COOKIE_CONSENT_UPDATED_EVENT = 'CookieInformationConsentGiven';

export const CookieConsentContext = createContext([] as CookieConsent[]);

// Context for managing and providing access to the array of cookie consents
export const CookieConsentsProvider = (props: CookieConsentContextProps): React.ReactElement => {
  const { children } = props;

  const [cookieConsents, setCookieConsents] = useState<CookieConsent[]>(() => getCookieConsents());

  const updateCookieConsents = useCallback(() => {
    const cookieConsents = getCookieConsents();
    setCookieConsents(cookieConsents);
  }, [setCookieConsents]);

  useEffect(() => {
    // Event listener to update the Consent after the cookie pop-up has been updated
    window.addEventListener(COOKIE_CONSENT_UPDATED_EVENT, updateCookieConsents);

    return () => {
      window.removeEventListener(COOKIE_CONSENT_UPDATED_EVENT, updateCookieConsents);
    };
  }, [updateCookieConsents]);

  return <CookieConsentContext.Provider value={cookieConsents}>{children}</CookieConsentContext.Provider>;
};
