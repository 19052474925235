import { useCallback, useEffect, useState } from 'react';

const isBrowser = typeof window !== 'undefined';

const INITIAL_VALUE = { top: 0, bottom: 0, left: 0, right: 0 };

interface ScrollPositionState {
  /**
   * The number of pixels scrolled vertically from the top.
   */
  top: number;

  /**
   * The number of pixels remaining to scroll to reach the bottom of the document.
   */
  bottom: number;

  /**
   * The number of pixels scrolled horizontally from the left.
   */
  left: number;

  /**
   * The number of pixels remaining to scroll to reach the right of the document.
   */
  right: number;
}

interface UseScrollPositionProps {
  /**
   * Determines if the scroll position should be enabled.
   * @default true
   */
  enabled?: boolean;
}

/**
 * The hook can be used to detect the scroll position in the window and
 * provides detailed information including top, bottom, left, and right positions.
 */
export const useScrollPosition = (props: UseScrollPositionProps = {}) => {
  const { enabled = true } = props;

  const [scrollPosition, setScrollPosition] = useState<ScrollPositionState>(INITIAL_VALUE);

  const handleScroll = useCallback(() => {
    const top = window.scrollY;
    const left = window.scrollX;
    const bottom = document.body.scrollHeight - window.innerHeight - top;
    const right = document.body.scrollWidth - window.innerWidth - left;

    setScrollPosition({ top, bottom, left, right });
  }, []);

  useEffect(() => {
    if (!isBrowser || !enabled) return;

    handleScroll();

    window.addEventListener('scroll', handleScroll);

    return () => window.removeEventListener('scroll', handleScroll);
  }, [enabled, handleScroll]);

  return scrollPosition;
};
