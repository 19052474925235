'use client';

import {
  useMemo,
  useState,
  useContext,
  useCallback,
  createContext,
  useTransition,
  useOptimistic,
  PropsWithChildren,
} from 'react';

import { Cart, ServiceType, DeliveryServiceType } from '@/bff-client';
import { useErrorBanner } from '@/contexts';

import {
  getCart,
  addCartItem,
  deleteCartItem,
  trackUpdateCart,
  updateCartServices,
  updateCartDeliveryService,
} from './actions';

export interface CartContextProps extends PropsWithChildren {
  /**
   * The cart for the user.
   */
  cart: Cart | null;
}

export interface CartContextValues extends CartContextProps {
  /**
   * Determines if a cart transition is pending during a mutation.
   */
  cartTransitionPending: boolean;
  /**
   * The total quantity for all the items inside the cart.
   */
  cartQuantity: number;
  /**
   * The callback is used to add a delivery service to the cart.
   */
  onChangeDeliveryService: (deliveryServiceType: DeliveryServiceType) => void;
  /**
   * The callback is used to add an item to the cart.
   */
  onAddToCart: (sku: string, quantity?: number) => void;
  /**
   * The callback is used to delete an item from the cart.
   */
  onDeleteItemFromCart: (sku: string) => void;
  /**
   * The callback is used to change the quantity of an item inside the cart.
   */
  onUpdateItemQuantity: (sku: string, quantity: number, quantityChange: number) => void;
  /**
   * The callback is used to update services for an item inside the cart.
   */
  onUpdateServices: (sku: string, services: ServiceType[]) => void;
  /**
   * The callback is used to fetch the latest cart state.
   */
  refetchCart: () => Promise<Cart>;
}

const CartContext = createContext({} as CartContextValues);

export const CartProvider = (props: CartContextProps): React.ReactElement => {
  const { children, cart: cartProp } = props;

  const [cartTransitionPending, startCartTransition] = useTransition();

  const { setErrorBanner } = useErrorBanner();

  const [cart, setCart] = useState<Cart | null>(cartProp);

  const [optimisticCart, setOptimisticCart] = useOptimistic(cart);

  const refetchCart = useCallback(async () => {
    const cart = await getCart();

    setCart(cart);

    return cart;
  }, []);

  const handleAddToCart = useCallback(
    async (sku: string, quantityChange = 1) => {
      try {
        const response = await addCartItem(sku, quantityChange);

        const cart = await refetchCart();

        trackUpdateCart(cart);

        return response;
      } catch {
        setErrorBanner(true);
      }
    },
    [refetchCart, setErrorBanner],
  );
  const handleDeleteItemFromCart = useCallback(
    async (sku: string) => {
      if (!cart) return;

      startCartTransition(async () => {
        setOptimisticCart((prevState) =>
          prevState
            ? {
                ...prevState,
                items: prevState.items.filter((item) => item.sku !== sku),
              }
            : prevState,
        );

        try {
          await deleteCartItem(sku);
          const cart = await refetchCart();
          trackUpdateCart(cart);
        } catch {
          setErrorBanner(true);
        }
      });
    },
    [cart, setOptimisticCart, refetchCart, setErrorBanner],
  );

  const handleUpdateItemQuantity = useCallback(
    async (sku: string, quantity: number, quantityChange: number) => {
      startCartTransition(async () => {
        setOptimisticCart((prevState) =>
          prevState
            ? {
                ...prevState,
                items: prevState.items.map((item) => (item.sku === sku ? { ...item, quantity } : item)),
              }
            : prevState,
        );

        try {
          await addCartItem(sku, quantityChange);
          const cart = await refetchCart();
          trackUpdateCart(cart);
        } catch {
          setErrorBanner(true);
        }
      });
    },
    [refetchCart, setOptimisticCart, setErrorBanner],
  );

  const handleChangeDeliveryService = useCallback(
    (deliveryServiceType: DeliveryServiceType) => {
      startCartTransition(async () => {
        setOptimisticCart((prevState) => (prevState ? { ...prevState, delivery: deliveryServiceType } : prevState));

        try {
          await updateCartDeliveryService(deliveryServiceType);

          const cart = await refetchCart();

          trackUpdateCart(cart);
        } catch {
          setErrorBanner(true);
        }
      });
    },
    [refetchCart, setOptimisticCart, setErrorBanner],
  );

  const handleUpdateServices = useCallback(
    (sku: string, services: ServiceType[]) => {
      startCartTransition(async () => {
        setOptimisticCart((prevState) =>
          prevState
            ? {
                ...prevState,
                items: prevState.items.map((item) => (item.sku === sku ? { ...item, extraServices: services } : item)),
              }
            : prevState,
        );

        try {
          await updateCartServices(sku, services);
          const cart = await refetchCart();
          trackUpdateCart(cart);
        } catch {
          setErrorBanner(true);
        }
      });
    },
    [refetchCart, setOptimisticCart, setErrorBanner],
  );

  /**
   * Each sku can have more than one instance of the item.
   * So, we need the total count for the all instances or quantities inside all the skus.
   */
  const cartQuantity = useMemo(() => cart?.items.reduce((a, b) => a + b.quantity, 0) || 0, [cart?.items]);

  return (
    <CartContext.Provider
      value={{
        refetchCart,
        cartQuantity,
        cart: optimisticCart,
        cartTransitionPending,
        onAddToCart: handleAddToCart,
        onUpdateServices: handleUpdateServices,
        onDeleteItemFromCart: handleDeleteItemFromCart,
        onUpdateItemQuantity: handleUpdateItemQuantity,
        onChangeDeliveryService: handleChangeDeliveryService,
      }}
    >
      {children}
    </CartContext.Provider>
  );
};

export const useCart = (): CartContextValues => {
  return useContext(CartContext);
};
