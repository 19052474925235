import { useCallback, useEffect, useRef, useState } from 'react';

const isBrowser = typeof window !== 'undefined';

const SCROLL_TIMEOUT = 100;

type UseScrollAxis = 'x' | 'y';

type UseScrollDirection = 'up' | 'down' | 'left' | 'right';

interface UseScrollDirectionProps {
  /**
   * Determines the axis for the scroll.
   * @default y
   */
  axis?: UseScrollAxis;
  /**
   * Determines if the scroll direction should be enabled.
   * @default
   */
  enabled?: boolean;
  /**
   * The threshold to trigger the scroll direction.
   * @default 0
   */
  threshold?: number;
}

/**
 * The useScrollDirection hook can be used to detect the scroll direction on the window.
 */
export const useScrollDirection = (props: UseScrollDirectionProps = {}) => {
  const { enabled = true, threshold = 0, axis = 'y' } = props;

  const prevScrollPositionRef = useRef(0);

  const scrollTimeoutRef = useRef(SCROLL_TIMEOUT);

  const [scrollDirection, setScrollDirection] = useState<UseScrollDirection | null>(null);

  const scrollUp = axis === 'y' ? 'up' : 'left';
  const scrollDown = axis === 'y' ? 'down' : 'right';

  const handleScroll = useCallback(() => {
    const prevScrollPosition = prevScrollPositionRef.current;
    const scrollPosition = axis === 'y' ? window.scrollY : window.scrollX;

    // Reset the scroll direction, when the user stops scrolling.
    window.clearTimeout(scrollTimeoutRef.current);

    scrollTimeoutRef.current = window.setTimeout(() => {
      setScrollDirection(null);
    }, SCROLL_TIMEOUT);

    const aboveThreshold = Math.abs(scrollPosition - prevScrollPosition) >= threshold;

    if (!aboveThreshold) return;

    setScrollDirection(scrollPosition > prevScrollPosition ? scrollDown : scrollUp);

    prevScrollPositionRef.current = Math.max(0, scrollPosition);
  }, [axis, scrollDown, scrollUp, threshold]);

  useEffect(() => {
    if (!isBrowser || !enabled) return;

    prevScrollPositionRef.current = axis === 'y' ? window.scrollY : window.scrollX;

    window.addEventListener('scroll', handleScroll);

    return () => window.removeEventListener('scroll', handleScroll);
  }, [axis, enabled, handleScroll]);

  return { scrollDirection, scrolling: scrollDirection !== null };
};
