import { getCookie, parseCookieConsents } from '@/utils';

import { CookieConsent } from './cookie-consent-context';

/**
 * Retrieves cookie consents from the 'CookieInformationConsent' cookie.
 * @returns An array of CookieConsent.
 */
export const getCookieConsents = (): CookieConsent[] => {
  const cookie = getCookie('CookieInformationConsent');

  return cookie ? parseCookieConsents(cookie) : [];
};
