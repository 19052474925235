import React from 'react';

import { Container, MailIcon, CallIcon, LocalShippingIcon, Stack, Grid, GridItem, Typography } from '@whiteaway/ui';

import { useTranslations } from '@/contexts';

import * as Styled from './header-top-bar.styled';
import { HeaderTopBarLink } from './header-top-bar-link';

/**
 * The HeaderTopBar component is used to render out the contact information inside the bar on top of the header.
 */
export const HeaderTopBar: React.FC = () => {
  const { translations } = useTranslations();

  const texts = translations.components.header.topBar;

  return (
    <Styled.Root>
      <Container>
        <Grid>
          <GridItem xl={8} md={8} sm={4}>
            <Styled.Stack direction="row">
              <HeaderTopBarLink href={`mailto: ${texts.email}`}>
                <MailIcon size="sm" />
                {texts.email}
              </HeaderTopBarLink>

              <HeaderTopBarLink href={`tel: ${texts.phone}`}>
                <CallIcon size="sm" />
                {texts.phone}
              </HeaderTopBarLink>
            </Styled.Stack>
          </GridItem>

          <Styled.DesktopGridItem xl={4}>
            <Stack spacing={1} direction="row" align="center" justify="flex-end">
              <LocalShippingIcon size="sm" />

              <Typography variant="caption" color="text.inverse">
                {texts.delivery}
              </Typography>
            </Stack>
          </Styled.DesktopGridItem>
        </Grid>
      </Container>
    </Styled.Root>
  );
};

HeaderTopBar.displayName = 'HeaderTopBar';

export default HeaderTopBar;
