'use client';

import styled, { css } from 'styled-components';

import { Container, Stack } from '@whiteaway/ui';

interface Props extends React.HtmlHTMLAttributes<HTMLDivElement> {}

/**
 * The component holds the root node for the footer.
 */
export const FooterRoot: React.FC<Props> = (props) => {
  const { children, ...rest } = props;

  return (
    <Root {...rest}>
      <Stack asElement={Container}>{children}</Stack>
    </Root>
  );
};

const Root = styled.footer`
  ${({ theme }) => css`
    margin-top: auto;
    padding: ${theme.getSpacing(8, 10)};
    background-color: ${theme.colors.background.strong};
  `};
`;
