import Link from 'next/link';
import styled from 'styled-components';

import { Button, ButtonProps, forwardRef } from '@whiteaway/ui';

interface Props extends ButtonProps {}

/**
 * The HeaderLink component is used render out a text-button with an associated icon inside the header
 */
export const HeaderLink = forwardRef<Props, 'a'>((props, ref) => {
  const { children, asElement = Link, ...rest } = props;

  return (
    <Root ref={ref} variant="text" size="sm" asElement={asElement} {...rest}>
      {children}
    </Root>
  );
});

HeaderLink.displayName = 'HeaderLink';

export default HeaderLink;

const Root = styled(Button)`
  span:hover {
    text-decoration: underline;
  }
`;
